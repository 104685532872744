import { Formik, Form, FormikHelpers } from "formik";
import * as Yup from "yup";
import { SelectField, LegalLinks } from "@/components";
import { PartnershipSubmitHandlerParams } from "@/types";
import { urlRegex } from "@/utils";
import { TextField } from "@material-ui/core";
import { Button } from "@narrative-software/narrative-web-ui";

// Hidden inputs
const hiddenInputs = {
  u: "14",
  f: "14",
  s: "",
  c: "0",
  m: "0",
  act: "sub",
  v: "2",
};

// Field types
export interface IWorkshopsFormFields {
  name: string;
  email: string;
  "workshop-name": string;
  "workshop-dates": string;
  "workshop-location": string;
  "workshop-website": string;
  "workshop-instagram": string;
  attendees: string;
  website: string;
  "workshop-count": string;
  sponsors: string;
  apps: string;
}

// Field map
const fieldMap: IWorkshopsFormFields = {
  name: "fullname",
  email: "email",
  "workshop-name": "field[69]",
  "workshop-dates": "field[70]",
  "workshop-location": "field[71]",
  "workshop-website": "field[72]",
  "workshop-instagram": "field[73]",
  attendees: "field[74]",
  website: "field[46]",
  "workshop-count": "field[75]",
  sponsors: "field[76]",
  apps: "field[51]",
};

// Initial values
const initialValues = Object.keys(fieldMap).reduce(
  (accumulator, currentValue) => ({
    ...accumulator,
    [currentValue]: "",
  }),
  {} as IWorkshopsFormFields,
);

// Validation
const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name required"),
  email: Yup.string().email("Invalid email").required("Email required"),
  "workshop-name": Yup.string().required("Workshop name required"),
  "workshop-dates": Yup.string().required("Workshop dates required"),
  "workshop-location": Yup.string().required("Workshop location required"),
  "workshop-website": Yup.string().matches(urlRegex, "Invalid URL").required("Workshop website required"),
  "workshop-instagram": Yup.string().optional(),
  attendees: Yup.string().optional(),
  website: Yup.string().matches(urlRegex, "Invalid URL").required("Website required"),
  "workshop-count": Yup.string().optional(),
  sponsors: Yup.string().optional(),
  apps: Yup.string().required("Narrative apps required"),
});

type Props = {
  onSubmit: <T extends Record<string, string>>({
    values,
    actions,
    fieldMap,
    hiddenInputs,
  }: PartnershipSubmitHandlerParams<T>) => Promise<void>;
};

const WorkshopsForm: React.FC<Props> = ({ onSubmit }) => {
  const handleSubmit = async (values: IWorkshopsFormFields, actions: FormikHelpers<IWorkshopsFormFields>) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    await onSubmit<any>({ values, actions, fieldMap, hiddenInputs });
  };

  return (
    <>
      <h2 className="text-h3 font-semibold leading-compact text-center mb-6 lg:text-h2">
        Send us your workshop details
      </h2>
      <div className="w-full">
        <Formik {...{ initialValues, validationSchema }} onSubmit={handleSubmit}>
          {({ values, errors, touched, handleChange, handleBlur, isSubmitting }) => {
            const getFieldProps = (name: keyof IWorkshopsFormFields, helperText?: string) => ({
              name,
              id: `form-workshops-${name}`,
              type: "text",
              value: values[name],
              error: touched[name] && !!errors[name],
              helperText: helperText ? (touched[name] && errors[name]) || helperText : touched[name] && errors[name],
              onChange: handleChange,
              onBlur: handleBlur,
              fullWidth: true,
            });

            return (
              <Form noValidate>
                <div className="grid grid-cols-1 gap-5 sm:grid-cols-2">
                  <TextField {...getFieldProps("name")} label="Name" />
                  <TextField {...getFieldProps("email")} label="Email" type="email" />
                  <TextField {...getFieldProps("workshop-name")} label="Workshop name" />
                  <TextField {...getFieldProps("workshop-dates")} label="Workshop dates" />
                  <TextField {...getFieldProps("workshop-location")} label="Workshop location" />
                  <TextField {...getFieldProps("workshop-website")} label="Workshop website" type="url" />
                  <TextField {...getFieldProps("workshop-instagram")} label="Workshop instagram" />
                  <TextField {...getFieldProps("attendees")} label="Number of attendees expected" />
                  <TextField {...getFieldProps("website")} label="Your own photography website" type="url" />
                  <TextField {...getFieldProps("workshop-count")} label="No. of workshops held yearly? (optional)" />
                  <TextField {...getFieldProps("sponsors")} label="Other sponsors" />
                  <SelectField
                    {...getFieldProps("apps")}
                    label="Which Narrative apps do you use?"
                    inputProps={{ style: { backgroundColor: "transparent" } }}
                  >
                    <option aria-label="None" value="" />
                    <option value="Narrative Select only">Narrative only</option>
                    <option value="Narrative Publish only">Publish only</option>
                    <option value="Narrative Select and Publish">Narrative and Publish</option>
                    <option value="I don’t use Narrative apps">I don’t use Narrative apps</option>
                  </SelectField>
                </div>

                <div className="mt-10 grid grid-cols-1 gap-5 sm:grid-cols-2 md:mt-14">
                  <div className="flex items-center">
                    <LegalLinks />
                  </div>
                  <div>
                    <Button type="submit" colour="black" isLoading={isSubmitting} showLoader>
                      Submit
                    </Button>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </>
  );
};

export default WorkshopsForm;

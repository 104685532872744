import { useState } from "react";
import Image from "next/image";
import { Container } from "@/components";
import { FormControl, MenuItem, Select, Slider } from "@material-ui/core";
import { HorizontalRule } from "@narrative-software/narrative-web-ui";

const MONTHS_PER_YEAR = 12;
const HOURS_PER_DAY = 8;
const WORKDAYS_PER_YEAR = 240; // 48 weeks * 5 days per week
const TIME_SAVED = 0.8;
const COST_PER_IMAGE = 0.03;

type EditTimeCostCalculatorProps = {
  title: string;
};

export const EditTimeCostCalculator = ({ title }: EditTimeCostCalculatorProps) => {
  const [hoursPerMonth, setHoursPerMonth] = useState(12);
  const [imagesPerMonth, setImagesPerMonth] = useState(2500);
  const [earningsPerYear, setEarningsPerYear] = useState(75_000);

  const editCostPerYear = imagesPerMonth * MONTHS_PER_YEAR * COST_PER_IMAGE;

  const hoursPerYear = hoursPerMonth * MONTHS_PER_YEAR;
  const workDaysSaved = (hoursPerYear * TIME_SAVED) / HOURS_PER_DAY;
  const valueOfTimeSaved = (earningsPerYear / WORKDAYS_PER_YEAR) * workDaysSaved;
  const ROI = valueOfTimeSaved - editCostPerYear;

  return (
    <section className="bg-off-white py-20 md:py-30">
      <Container>
        <HorizontalRule />
        <h2 className="mt-6 text-h3 font-semibold leading-snug text-center md:text-h2">{title}</h2>
        {/* Controls section */}
        <div className="grid md:grid-cols-2 items-center justify-center mt-12 -mx-5 md:mx-0">
          <div className="grid grid-cols-6 gap-x-5 py-15 bg-off-white flex-1 mx-5 sm:m-0 bg-mankey">
            <div className="col-start-2 col-span-4">
              <div className="text-xxxs">
                How many hours a <strong>month</strong> do you spend editing?
              </div>
              <div className="mt-3 -mb-1">{hoursPerMonth} hours</div>
              <Slider
                value={hoursPerMonth}
                min={12}
                max={20}
                step={2}
                onChange={(_, value) => setHoursPerMonth(value as number)}
              />
              <div className="text-xxxs">
                How many images a <strong>month</strong> do you edit?
              </div>
              <div className="mt-3 -mb-1">{imagesPerMonth} images</div>
              <Slider
                value={imagesPerMonth}
                min={500}
                max={3000}
                step={500}
                onChange={(_, value) => setImagesPerMonth(value as number)}
              />
              <div className="text-xxxs mt-4">
                What do you earn from photography <strong>yearly</strong>?
              </div>
              <FormControl fullWidth>
                <Select value={earningsPerYear} onChange={(e) => setEarningsPerYear(e.target.value as number)}>
                  <MenuItem value={50_000}>$50,000</MenuItem>
                  <MenuItem value={75_000}>$75,000</MenuItem>
                  <MenuItem value={100_000}>$100,000</MenuItem>
                  <MenuItem value={150_000}>$150,000</MenuItem>
                  <MenuItem value={200_000}>$200,000</MenuItem>
                  <MenuItem value={300_000}>$300,000</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          {/* Blue Section */}
          <div className="relative divide-y divide-ekans leading-snug text-white">
            {/* Blue Section Above The Line */}
            <div className="pt-13 pb-9 px-10 lg:px-15 flex flex-col text-center items-center relative z-10">
              <h5 className="text-lg sm:text-22">Yearly return on investment using Narrative:</h5>
              <div className="flex justify-center">
                <span className="text-2xl mt-4 mr-1">$</span>
                <span className="text-6xl font-semibold">{Intl.NumberFormat("en").format(Math.round(ROI))}</span>
              </div>
              <div className="text-22 hidden md:block">
                and {Intl.NumberFormat("en").format(Math.round(10 * workDaysSaved) / 10)} work days
              </div>
            </div>
            {/* Blue Section Below The Line */}
            <div className="grid grid-cols-6 gap-x-5 gap-y-4 py-5 text-xxs divide-y relative z-10">
              <div className="grid gap-y-3 grid-cols-[1fr_auto] justify-between col-span-4 col-start-2 pt-4 gap-x-4">
                <span>% editing time photographers save after switching to Narrative</span>
                <span className="text-right">80%</span>
                <span>Value of time saved by using Edit per year</span>
                <span className="text-right">
                  {Intl.NumberFormat("en", { style: "currency", currency: "USD", maximumFractionDigits: 0 }).format(
                    Math.round(valueOfTimeSaved),
                  )}
                </span>
                <span>Cost of Edit @6c per image per year</span>
                <span className="text-right">
                  {Intl.NumberFormat("en", { style: "currency", currency: "USD", maximumFractionDigits: 0 }).format(
                    editCostPerYear,
                  )}
                </span>
              </div>
              <div className="grid gap-y-3 grid-cols-[1fr_auto] justify-between col-span-4 col-start-2 pt-5 pb-9">
                <span>Total saved</span>
                <span>
                  {Intl.NumberFormat("en", { style: "currency", currency: "USD", maximumFractionDigits: 0 }).format(
                    Math.round(ROI),
                  )}
                </span>
              </div>
            </div>

            <div className="absolute overflow-hidden top-0 right-0 left-0 bottom-0">
              <Image
                src="/images/textures/ekans-bg@2x.png"
                alt="Narrative | Background Texture"
                layout="fill"
                objectFit="cover"
                sizes="610px"
                loading="eager"
              />
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

import Image from "next/image";
import { Section, Container, ImageWithText, EyeIcon, AlertIcon, ZoomFaceIcon, LightningBoltIcon } from "@/components";
import Screenshot1Overlay from "@/images/select/select-screenshot-1-overlay@2x.png";
import Screenshot1 from "@/images/select/select-screenshot-1@2x.png";
import Screenshot2Overlay from "@/images/select/select-screenshot-2-overlay@2x.png";
import Screenshot2 from "@/images/select/select-screenshot-2@2x.png";
import Screenshot3 from "@/images/select/select-screenshot-3@2x.png";
import Screenshot4 from "@/images/select/select-screenshot-4@2x.png";
import { HorizontalRule } from "@narrative-software/narrative-web-ui";

type Props = {
  title: string;
};

/**
 * @deprecated use ProductFeatures instead with the Product Features Section block
 */
const SelectKeyFeaturesSection: React.FC<Props> = ({ title }) => (
  <Section>
    <Container>
      <HorizontalRule bottomMargin />
      <h2 className="text-h2 font-semibold leading-compact mb-10 lg:mb-16 text-center">{title}</h2>

      {/* 1 */}
      <ImageWithText
        title="Start reviewing images almost instantly"
        mainImageSrc={Screenshot1}
        width={599}
        height={393}
        orientation="image-left"
        className="mb-10 md:mb-18"
        icon={<LightningBoltIcon />}
        overlaidImages={
          <div className="flex absolute" style={{ width: "61.2%", bottom: "-3%", right: "-3%" }}>
            <Image src={Screenshot1Overlay} alt="" width="366" height="97" placeholder="blur" unoptimized />
          </div>
        }
      >
        Import thousands of RAWs in seconds. No wait times to navigate through your photoshoots. Narrative is built from
        the bottom up to save you as much time as possible.
      </ImageWithText>

      {/* 2 */}
      <ImageWithText
        title="Spend less time second guessing"
        mainImageSrc={Screenshot2}
        width={599}
        height={371}
        orientation="image-right"
        className="mb-10 md:mb-22"
        icon={<EyeIcon />}
        overlaidImages={
          <div className="flex absolute" style={{ width: "97%", top: "24%", right: "-2%" }}>
            <Image src={Screenshot2Overlay} alt="" width="595" height="380" placeholder="blur" unoptimized />
          </div>
        }
      >
        Eye and focus assessments allow you to quickly identify if your subjects are in or out of focus and if their
        eyes are open or closed.
      </ImageWithText>

      {/* 3 */}
      <ImageWithText
        title="See face details faster"
        mainImageSrc={Screenshot3}
        width={599}
        height={386}
        orientation="image-left"
        className="mb-10 md:mb-18"
        icon={<ZoomFaceIcon />}
      >
        The Close-ups panel ensures you can see everyone’s faces perfectly – no more time spent zooming and panning for
        each face. If you do need to zoom, just hit the spacebar to zoom straight to the most important face.
      </ImageWithText>

      {/* 4 */}
      <ImageWithText
        title="Get alerted to your worst images"
        mainImageSrc={Screenshot4}
        width={599}
        height={386}
        orientation="image-right"
        icon={<AlertIcon />}
      >
        Image Assessments allow you to quickly identify the worst images from your shoot and then hide them, meaning you
        are viewing 20-30% fewer images per shoot.
      </ImageWithText>
    </Container>
  </Section>
);

export default SelectKeyFeaturesSection;

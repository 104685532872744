import { ACCOUNT_BASE_URL } from "@/config";

const awardsBase = "photography-awards";

export const ROUTES = {
  BASE: {
    SLUG: "",
    LABEL: "Narrative",
  },
  SELECT: {
    BASE: {
      SLUG: "select",
      LABEL: "Select",
    },
    PRICING: {
      SLUG: "select/pricing",
      LABEL: "Pricing",
    },
    LESSONS: {
      SLUG: "select/lessons",
      LABEL: "Lessons",
    },
    SCENES_VIEW: {
      SLUG: "select/scenes-view",
      LABEL: "Scenes View",
    },
    FACE_ASSESSMENTS: {
      SLUG: "select/face-assessments",
      LABEL: "Face Assessments",
    },
    THE_CLOSE_UPS_PANEL: {
      SLUG: "select/the-close-ups-panel",
      LABEL: "The Close-Ups Panel",
    },
  },
  EDIT: {
    BASE: {
      SLUG: "edit",
      LABEL: "Edit",
    },
    PRICING: {
      SLUG: "edit/pricing",
      LABEL: "Pricing",
    },
    STYLES: {
      SLUG: "edit/styles",
      LABEL: "AI Presets",
    },
  },
  PUBLISH: {
    BASE: {
      SLUG: "publish",
      LABEL: "Publish",
    },
    PRICING: {
      SLUG: "publish/pricing",
      LABEL: "Pricing",
    },
    LESSONS: {
      SLUG: "publish/lessons",
      LABEL: "Lessons",
    },
  },
  CHANGELOG: {
    SLUG: "changelog",
    LABEL: "Changelog",
  },
  LESSONS: {
    SLUG: "lessons",
    LABEL: "Lessons",
  },
  BLOG: {
    SLUG: "blog",
    LABEL: "Blog",
  },
  PARTNERSHIPS: {
    SLUG: "partnerships",
    LABEL: "Partnerships",
  },
  ABOUT: {
    SLUG: "about",
    LABEL: "About Us",
  },
  REFERRALS: {
    SLUG: "referrals",
    LABEL: "Referrals",
  },
  CAREERS: {
    SLUG: "careers",
    LABEL: "Careers",
  },
  WINDOWS: {
    SLUG: "windows",
    LABEL: "Windows",
  },
  TERMS: {
    SLUG: "terms-and-conditions",
    LABEL: "Terms & Conditions",
  },
  PRIVACY: {
    SLUG: "privacy-policy",
    LABEL: "Privacy Policy",
  },

  AWARDS: {
    BASE: {
      SLUG: awardsBase,
      LABEL: "Narrative International Photography Awards",
    },
    ABOUT: {
      SLUG: `${awardsBase}/about`,
      LABEL: "About",
    },
    CATEGORIES: {
      SLUG: `${awardsBase}/categories`,
      LABEL: "Categories & Prizes",
    },
    ENTER: {
      SLUG: `${awardsBase}/enter`,
      LABEL: "Enter",
    },
    JUDGES: {
      SLUG: `${awardsBase}/judges`,
      LABEL: "Judges",
    },
    COMPLETE: {
      SLUG: `${awardsBase}/complete`,
      LABEL: "Complete",
    },
    TERMS: {
      SLUG: "awards-terms-and-conditions",
      LABEL: "Awards terms & conditions",
    },
  },

  BLACK_FRIDAY: {
    SLUG: "black-friday",
    LABEL: "Black Friday",
  },

  BLACK_FRIDAY_AMBASSADOR_SALE: {
    SLUG: "black-friday-extra",
    LABEL: "Black Friday Extra",
  },
};

export const EXTERNAL = {
  BASE: {
    HREF: `${ACCOUNT_BASE_URL}`,
    LABEL: "Narrative",
  },
  SELECT: {
    GET_STARTED: {
      HREF: `${ACCOUNT_BASE_URL}/get-started/select`,
      LABEL: "Get started",
    },
    DOWNLOAD: {
      HREF: `${ACCOUNT_BASE_URL}/select/download`,
      LABEL: "Download",
    },
    SUPPORT: {
      HREF: "https://help.narrative.so/en/",
      LABEL: "Help & Support",
    },
  },
  EDIT: {
    GET_STARTED: {
      HREF: `${ACCOUNT_BASE_URL}/get-started/select?edit=true`, // query param does nothing except it's used in places as a key, so we can't use the same key for both Select and Edit
      LABEL: "Get started",
    },
    DOWNLOAD: {
      HREF: `${ACCOUNT_BASE_URL}/select/download?edit=true`, // query param does nothing except it's used in places as a key, so we can't use the same key for both Select and Edit
      LABEL: "Download",
    },
    SUPPORT: {
      HREF: "https://help.narrative.so/en/",
      LABEL: "Help & Support",
    },
  },
  PUBLISH: {
    BASE: {
      HREF: `${ACCOUNT_BASE_URL}`,
      LABEL: "Publish",
    },
    GET_STARTED: {
      HREF: `${ACCOUNT_BASE_URL}/get-started/publish`,
      LABEL: "Get started",
    },
    DOWNLOAD: {
      HREF: `${ACCOUNT_BASE_URL}/publish/download`,
      LABEL: "Download",
    },
    SUPPORT: {
      HREF: "https://help.narrative.so/en/",
      LABEL: "Help & Support",
    },
  },
  SUBSCRIPTIONS: {
    HREF: `${ACCOUNT_BASE_URL}/subscriptions`,
    LABEL: "Subscriptions",
  },
  SIGN_IN: {
    HREF: `${ACCOUNT_BASE_URL}/login`,
    LABEL: "Sign in",
  },
  SIGN_UP: {
    HREF: `${ACCOUNT_BASE_URL}/sign-up`,
    LABEL: "Sign up",
  },
  GET_STARTED: {
    HREF: `${ACCOUNT_BASE_URL}/get-started`,
    LABEL: "Get started",
  },
  CHECKOUT: {
    HREF: `${ACCOUNT_BASE_URL}/checkout`,
    LABEL: "Checkout",
  },
  REFERRALS: {
    HREF: `${ACCOUNT_BASE_URL}/referrals`,
    LABEL: "Referrals",
  },
  SUPPORT: {
    HREF: "https://help.narrative.so/",
    LABEL: "Support",
  },
};

export const DOWNLOAD = {
  SELECT: {
    HREF: "https://updates.narrative.so/select-latest.html",
    LABEL: "Download Narrative",
  },
  PUBLISH: {
    HREF: "https://updates.narrative.so/narrative-publish-installer-latest.html",
    LABEL: "Download Publish",
  },
};

import { Formik, Form, FormikHelpers } from "formik";
import * as Yup from "yup";
import { SelectField, LegalLinks } from "@/components";
import { PartnershipSubmitHandlerParams } from "@/types";
import { urlRegex } from "@/utils";
import { TextField } from "@material-ui/core";
import { Button } from "@narrative-software/narrative-web-ui";

const hiddenInputs = {
  u: "6",
  f: "6",
  s: "",
  c: "0",
  m: "0",
  act: "sub",
  v: "2",
};

// Field types
export interface IPaidContentFormFields {
  name: string;
  email: string;
  website: string;
  instagram: string;
  youtube: string;
  tiktok: string;
  facebook: string;
  apps: string;
  "business-duration": string;
  workshops: string;
  awards: string;
  idea: string;
  platform: string;
  "platform-location": string;
  "content-length": string;
  rate: string;
}

// Field map
const fieldMap: IPaidContentFormFields = {
  name: "fullname",
  email: "email",
  website: "field[46]",
  instagram: "field[47]",
  youtube: "field[48]",
  tiktok: "field[49]",
  facebook: "field[50]",
  apps: "field[51]",
  "business-duration": "field[52]",
  workshops: "field[55]",
  awards: "field[54]",
  idea: "field[56]",
  platform: "field[57]",
  "platform-location": "field[58]",
  "content-length": "field[59]",
  rate: "field[60]",
};

// Initial values
const initialValues = Object.keys(fieldMap).reduce(
  (accumulator, currentValue) => ({
    ...accumulator,
    [currentValue]: "",
  }),
  {} as IPaidContentFormFields,
);

// Validation
const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name required"),
  email: Yup.string().email("Invalid email").required("Email required"),
  website: Yup.string().matches(urlRegex, "Invalid URL").required("Website required"),
  instagram: Yup.string().required("Instagram required"),
  youtube: Yup.string().optional(),
  tiktok: Yup.string().optional(),
  facebook: Yup.string().matches(urlRegex, "Invalid Facebook URL").optional(),
  apps: Yup.string().required("Narrative apps required"),
  "business-duration": Yup.string().required("Business duration required"),
  workshops: Yup.string().optional(),
  awards: Yup.string().optional(),
  idea: Yup.string().required("Content idea required"),
  platform: Yup.string().required("Platform required"),
  "platform-location": Yup.string().required("Platform location required"),
  "content-length": Yup.string().required("Content length required"),
  rate: Yup.string().required("Rate required"),
});

type Props = {
  onSubmit: <T extends Record<string, string>>({
    values,
    actions,
    fieldMap,
    hiddenInputs,
  }: PartnershipSubmitHandlerParams<T>) => Promise<void>;
};

const PaidContentForm: React.FC<Props> = ({ onSubmit }) => {
  const handleSubmit = async (values: IPaidContentFormFields, actions: FormikHelpers<IPaidContentFormFields>) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    await onSubmit<any>({ values, actions, fieldMap, hiddenInputs });
  };

  return (
    <>
      <h2 className="text-h3 font-semibold leading-compact text-center mb-6 lg:text-h2">Submit your ideas here:</h2>
      <div className="w-full">
        <Formik {...{ initialValues, validationSchema }} onSubmit={handleSubmit}>
          {({ values, errors, touched, handleChange, handleBlur, isSubmitting }) => {
            const getFieldProps = (name: keyof IPaidContentFormFields, helperText?: string) => ({
              name,
              id: `form-paid-content-${name}`,
              type: "text",
              value: values[name],
              error: touched[name] && !!errors[name],
              helperText: helperText ? (touched[name] && errors[name]) || helperText : touched[name] && errors[name],
              onChange: handleChange,
              onBlur: handleBlur,
              fullWidth: true,
            });

            return (
              <Form noValidate>
                <div className="grid grid-cols-1 gap-5 sm:grid-cols-2">
                  <TextField {...getFieldProps("name")} label="Name" />
                  <TextField {...getFieldProps("email")} label="Email" type="email" />
                  <TextField {...getFieldProps("website")} label="Website" type="url" />
                  <TextField {...getFieldProps("instagram")} label="Instagram" />
                  <TextField {...getFieldProps("youtube")} label="YouTube (optional)" />
                  <TextField {...getFieldProps("tiktok")} label="TikTok (optional)" />
                  <TextField {...getFieldProps("facebook")} label="Facebook (optional)" type="url" />
                  <div className="hidden sm:block" />
                  <SelectField
                    {...getFieldProps("apps")}
                    label="Which Narrative apps do you use?"
                    inputProps={{ style: { backgroundColor: "transparent" } }}
                  >
                    <option aria-label="None" value="" />
                    <option value="Narrative Select only">Narrative only</option>
                    <option value="Narrative Publish only">Publish only</option>
                    <option value="Narrative Select and Publish">Narrative and Publish</option>
                    <option value="I don’t use Narrative apps">I don’t use Narrative apps</option>
                  </SelectField>
                  <TextField {...getFieldProps("business-duration")} label="How long have you been in business?" />
                </div>

                <div className="mt-6 grid grid-cols-1 gap-5">
                  <TextField
                    {...getFieldProps(
                      "workshops",
                      "Tell us about any workshops or conferences you’ve held or spoken at",
                    )}
                    label="Workshops or conferences"
                    multiline
                  />
                  <TextField
                    {...getFieldProps(
                      "awards",
                      "Tell us about awards, accolades or moments you are most proud of in your photography career",
                    )}
                    label="Awards, accolades or moments"
                    multiline
                  />
                  <TextField {...getFieldProps("idea")} label="Your content idea" multiline />
                </div>

                <div className="mt-6 grid grid-cols-1 gap-5 sm:grid-cols-2">
                  <TextField {...getFieldProps("platform")} label="What platform will this be shared on?" />
                  <TextField
                    {...getFieldProps("platform-location", "(e.g. Instagram reel)")}
                    label="Where on the platform it will be shared?"
                  />
                  <TextField {...getFieldProps("content-length")} label="How long will the content be?" />
                  <TextField {...getFieldProps("rate")} label="What is your rate for this content?" />
                </div>

                <div className="mt-10 grid grid-cols-1 gap-5 sm:grid-cols-2 md:mt-14">
                  <div className="flex items-center">
                    <LegalLinks />
                  </div>
                  <div>
                    <Button type="submit" colour="black" isLoading={isSubmitting} showLoader>
                      Submit
                    </Button>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </>
  );
};

export default PaidContentForm;

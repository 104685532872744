import {
  renderMetaTags,
  renderMarkRule,
  StructuredText,
  StructuredTextGraphQlResponse,
  TitleMetaLinkTag,
  useQuerySubscription,
} from "react-datocms";
import { isEmptyDocument } from "datocms-structured-text-utils";
import { GetStaticProps } from "next";
import { Api } from "@/api";
import { Layout, SEO, Container, CredibilityBar, NarrativeWorkflowSection } from "@/components";
import { CtaWithImageSection } from "@/components/home/CtaWithImageSection";
import { FeaturesSection } from "@/components/home/FeaturesSection";
import {
  PhotographyAwardsCtaSection,
  PhotographyAwardsCtaSectionProps,
} from "@/components/home/PhotographyAwardsCtaSection";
import { TextBesideImage } from "@/components/home/TextBesideImage";
import { DATOCMS_API_TOKEN } from "@/config";
import {
  Carousel,
  CarouselFragmentType,
  Stat,
  StatFragmentType,
  carouselFragment,
  metaTagsFragment,
  responsiveImageFragment,
  statFragment,
} from "@/modules";
import { ImageType, Nullable, Subscription } from "@/types";
import { isOdd } from "@/utils";
import { ButtonAppearance, ButtonColour } from "@narrative-software/narrative-web-ui";

type CtaSection = {
  id: string;
  leadLine: string;
  title: string;
  description: string;
  buttonColor: ButtonColour;
  ctaText: string;
  ctaUrl: string;
  image: ImageType;
};

type WorkflowStep = { title: string; description: string };

export interface IHomePage {
  heroTitle: string;
  heroDescription: string;
  heroImage: ImageType;
  heroBackgroundImage: ImageType;
  heroCtas: { text: string; url: string; buttonColor: ButtonColour; buttonAppearance: ButtonAppearance }[];
  featuresTitle: string;
  featureSections: { id: string; title: string; description: string; icon: ImageType; image: ImageType }[];
  stats: Nullable<StatFragmentType>;
  ctaSections: CtaSection[];
  testimonials: Nullable<CarouselFragmentType>;
  ctaSection: CtaSection;
  photographyAwardsCtaSection?: PhotographyAwardsCtaSectionProps;
  workflowTitle: string;
  highlightTitle: string;
  highlightDescription: string;
  highlightImage: ImageType;
  highlightBackgroundImage: ImageType;
  seo: TitleMetaLinkTag[];
}

type HomePageQueryResponse = {
  page: IHomePage;
  banner: {
    enabled: boolean;
    content: StructuredTextGraphQlResponse;
  };
};

type Props = {
  preview: boolean;
  subscription: Subscription<HomePageQueryResponse>;
};

const HomePage: React.FC<Props> = ({ preview, subscription }) => {
  const { data: { page: data, banner } = {} } = useQuerySubscription(subscription);
  if (!data) return null;

  const { seo, stats, testimonials, ctaSection, photographyAwardsCtaSection } = data;

  return (
    <Layout preview={preview} className="justify-center" siteBanner={banner ? <SiteBanner {...banner} /> : null}>
      <SEO
        title="Narrative | Image selection | Blogging software | Photographers"
        imageUrl="/images/share-image-select.jpg"
      >
        {renderMetaTags(seo)}
      </SEO>

      <CtaWithImageSection
        title={data.heroTitle}
        alignImage="right"
        description={data.heroDescription}
        image={data.heroImage}
        backgroundImage={data.heroBackgroundImage}
        ctas={data.heroCtas}
        className="text-white"
        headingLevel="h1"
      />

      <FeaturesSection title={data.featuresTitle} sections={data.featureSections} />

      {stats ? <Stat {...stats} /> : null}

      {testimonials && <Carousel {...testimonials} />}

      {data.ctaSections.map((ctaSection, i) => (
        <CtaWithImageSection
          key={ctaSection.id}
          leadLine={ctaSection.leadLine}
          title={ctaSection.title}
          alignImage={isOdd(i) ? "left" : "right"}
          description={ctaSection.description}
          image={ctaSection.image}
          ctas={[
            {
              text: ctaSection.ctaText,
              url: ctaSection.ctaUrl,
              buttonColor: ctaSection.buttonColor,
              buttonAppearance: "primary",
            },
          ]}
        />
      ))}

      <NarrativeWorkflowSection
        title={data.workflowTitle}
        steps={[
          {
            title: "Import",
            description: "Import thousands of RAWs in seconds",
          },
          {
            title: "Cull",
            description: "Cull your photoshoot in half the time",
          },
          {
            title: "Edit",
            description: "AI editing in your style. 1-click ship to Lightroom",
          },
        ]}
        className="bg-off-white"
      />

      <CtaWithImageSection
        leadLine={ctaSection.leadLine}
        title={ctaSection.title}
        alignImage="left"
        description={ctaSection.description}
        image={ctaSection.image}
        ctas={[
          {
            text: ctaSection.ctaText,
            url: ctaSection.ctaUrl,
            buttonColor: ctaSection.buttonColor,
            buttonAppearance: "primary",
          },
        ]}
      />

      <Container>
        <CredibilityBar label="Featured in:" className="w-full mt-15 mb-17 bg-mankey" />
      </Container>
      {photographyAwardsCtaSection && <PhotographyAwardsCtaSection {...photographyAwardsCtaSection} />}

      <TextBesideImage {...data} />
    </Layout>
  );
};

type SiteBannerProps = HomePageQueryResponse["banner"];

function SiteBanner({ content, enabled }: SiteBannerProps) {
  return enabled && !isEmptyDocument(content) ? (
    <div className="bg-stealth-bomber text-white px-7 py-4 flex justify-center text-center">
      <StructuredText
        data={content}
        customMarkRules={[
          renderMarkRule("strong", ({ children, key }) => {
            return (
              <strong key={key} className="font-medium">
                {children}
              </strong>
            );
          }),
        ]}
      />
    </div>
  ) : null;
}

/**
 * Static props
 */
export const getStaticProps: GetStaticProps<Props> = async ({ preview = false }) => {
  const graphqlRequest = {
    preview,
    query: `
        {
          page: pageHomeV3 {
            heroTitle
            heroDescription
            heroCtas {
              ...on CtaRecord {
                text
                url
                buttonColor
                buttonAppearance
              }
            }
            heroImage {
              responsiveImage(imgixParams: { auto: format, w: 1680, h: 458 }) {
                ...responsiveImageFragment
              }
            }
            heroBackgroundImage {
              responsiveImage(imgixParams: { auto: format, w: 1680, h: 458 }) {
                ...responsiveImageFragment
              }
            }
            featuresTitle
            featureSections {
              ...on FeatureSectionRecord {
                id
                title
                description
                icon {
                  responsiveImage(imgixParams: { auto: format, w: 100, h: 30 }) {
                    ...responsiveImageFragment
                  }
                }
                image {
                  responsiveImage(imgixParams: { auto: format, w: 600, h: 500 }) {
                    ...responsiveImageFragment
                  }
                }
              }
            }
            stats {
              ...statFragment
            }
            ctaSections {
              ...on CtaSectionRecord {
                id
                leadLine
                title
                description
                buttonColor
                ctaText
                ctaUrl
                image {
                  responsiveImage(imgixParams: { auto: format, w: 600, h: 500 }) {
                    ...responsiveImageFragment
                  }
                }
              }
            }
            testimonials {
              ...testimonialCarouselFragment
            }
            ctaSection {
              title
              leadLine
              description
              buttonColor
              ctaText
              ctaUrl
              image {
                responsiveImage(imgixParams: { auto: format, w: 600, h: 500 }) {
                  ...responsiveImageFragment
                }
              }
            }
            photographyAwardsCtaSection {
              title
              description
              ctaText
              logo {
                responsiveImage(imgixParams: { auto: format, w: 150, q: 100 }) {
                  ...responsiveImageFragment
                }
              }
              mainImage {
                responsiveImage(imgixParams: { auto: format, w: 700 }) {
                  ...responsiveImageFragment
                }
              }
              backgroundImage {
                responsiveImage(imgixParams: { auto: format, w: 700 }) {
                  ...responsiveImageFragment
                }
              }              
            }            
            workflowTitle
            highlightTitle
            highlightDescription
            highlightImage {
              responsiveImage(imgixParams: { auto: format, w: 700, h: 460 }) {
                ...responsiveImageFragment
              }
            }
            highlightBackgroundImage {
              responsiveImage(imgixParams: { auto: format, w: 1920, h: 700 }) {
                ...responsiveImageFragment
              }
            }
            seo: _seoMetaTags {
              ...metaTagsFragment
            }
          }
          banner: moduleSiteBanner {
            enabled
            content {
              value
            }
          }
        }
        ${statFragment}
        ${carouselFragment}
        ${metaTagsFragment}
        ${responsiveImageFragment}
      `,
  };

  const {
    data: { data: initialData },
  } = await Api.datocmsGraphqlRequest<HomePageQueryResponse>(graphqlRequest);

  return {
    props: {
      preview,
      subscription: preview
        ? {
            ...graphqlRequest,
            initialData,
            token: DATOCMS_API_TOKEN,
          }
        : {
            initialData,
            enabled: false,
          },
    },
  };
};

export default HomePage;

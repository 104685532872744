import classNames from "classnames";
import Link from "next/link";
import { RightArrowIcon } from "@/components";
import { StyleTile } from "@/components/shared/StyleTile";
import { ROUTES } from "@/config";
import { Artist, ArtistStyle } from "@/types";
import { HorizontalRule } from "@narrative-software/narrative-web-ui";

type AdditionalStyle = Pick<ArtistStyle, "id" | "name" | "slug" | "description" | "image1After"> & {
  artist: Artist;
};

type AdditionalStylesSectionProps = { additionalStyles: AdditionalStyle[] };

function AdditionalStylesSection({ additionalStyles }: AdditionalStylesSectionProps) {
  return (
    <section className="md:py-[120px] py-[80px] bg-mankey">
      <div className="max-w-[1212px] mx-auto px-5">
        <HorizontalRule />
        <div className="relative">
          <h2 className="text-h2 font-semibold leading-compact mb-11 mt-7 text-center">You may also like</h2>
          <ViewMoreLink className="hidden md:flex md:absolute right-0 top-[15px]" />
        </div>
        <div className="grid md:grid-cols-2 grid-flow-row gap-5 grid-cols-1 lg:grid-cols-3">
          {additionalStyles.map((style) => (
            <StyleTile
              key={style.id}
              slug={style.slug}
              description={style.description}
              image1After={style.image1After}
              name={style.name}
              artist={style.artist}
            />
          ))}
        </div>
        <ViewMoreLink className="md:hidden md:absolute mt-5" />
      </div>
    </section>
  );
}

type ViewMoreLinkProps = { className?: string };

function ViewMoreLink({ className }: ViewMoreLinkProps) {
  return (
    <Link href={`/${ROUTES.EDIT.STYLES.SLUG}`} passHref>
      <a
        className={classNames(
          "uppercase text-xxs font-[600] flex items-center gap-[13px] static justify-center",
          className,
        )}
      >
        View all AI Presets
        <RightArrowIcon />
      </a>
    </Link>
  );
}

export { AdditionalStylesSection };
export type { AdditionalStyle };

import Link from "next/link";
import { ROUTES } from "@/config";
import { AnchorButton, PromoBanner } from "@narrative-software/narrative-web-ui";

const MidPromoSaleBanner = () => (
  <PromoBanner
    title={
      <div style={{ width: 600 }}>
        Join thousands of photographers blogging with <u>Publish</u>
      </div>
    }
    backgroundImagePath={`/images/mid-banner-black-friday.png`}
    description="Get started with Publish now with up to 50% off"
    form={
      <Link href={`/${ROUTES.PUBLISH.PRICING.SLUG}`} passHref>
        <AnchorButton className="mt-2 sm:w-1/2 sm:mt-0" colour={"slowpoke"}>
          Shop sale
        </AnchorButton>
      </Link>
    }
  />
);

export default MidPromoSaleBanner;
